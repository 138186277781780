/**
 * @namespace helpers
 */

/**
 * @method mutateLocaleParams
 * @description Converts non-standard NGP locales to an ISO compliant language/country string for use in native JavaScript functions e.g. date parsing and formatting
 *
 * @memberof helpers
 *
 *
 * @param {string} [locale=en_GB] - Locale string to check against for language and country exceptions in the NGP format e.g. `en_GB`
 *
 * @returns {string} - String in the ISO standard format e.g. `en-GB`
 *
 * @example
 * mutateLocaleParams('ls_LA'); // => es-AR
 * mutateLocaleParams('ue_US'); // => en-US
 *
 */

export default (locale = 'en_GB') => {
    const localeArray = locale.split('_');

    let language = localeArray[0] || 'en';
    let country = localeArray[1] || 'GB';

    const languageExceptions = {
        qf: 'fr',
        ls: 'es',
        ue: 'en',
        bp: 'pt'
    };

    const countryExceptions = {
        la: 'AR',
        mf: 'AO',
        ap: 'HK'
    };

    /* rebuild the locale */

    language = languageExceptions[language.toLowerCase()] ? languageExceptions[language.toLowerCase()] : language;
    country = countryExceptions[country.toLowerCase()] ? countryExceptions[country.toLowerCase()] : country;

    locale = `${language}_${country}`;

    return locale.replace(/_/g, '-');
};
