export default (
    state = {
        page: true,
        navigation: true,
        footer: true,
        copyright: false,
        country: false,
        labels: false
    },
    action
) => {
    switch (action.type) {
        case 'LOADING':
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};
