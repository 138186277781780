/**
 * @namespace helpers
 */

export default (string = '') => {
    /**
     * @method stripSlashes
     * @description Strips additional escaping slashes from a string, e.g. to take a regex test from a JSON payload or inherited prop
     *
     * @param {string} string - The string to be stripped.
     *
     * @memberof helpers
     *
     * @returns {string}
     *
     * @example
     * return stripSlashes('^[A-Z]{1,2}[0-9][0-9A-Z]?\\\\s?[0-9][A-Z]{2}$'); // => ^[A-Z]{1,2}[0-9][0-9A-Z]?\\s?[0-9][A-Z]{2}$
     *
     */

    return string.replace(/\\\\/g, '\\');
};
