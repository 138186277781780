import React from 'react';
import './Loader.scss';

function Loader() {
  return (
    <div className="spinner-wrapper">
      <div className="spinner" />
    </div>
  );
}

export default Loader;
