const port = 3000;
const watchPort = 3001;
const useLocalApi = process.env.NODE_ENV === 'production' ? false : true;
const apiPath = 'http://localhost:8080';
const proSonyApi = process.env.LAMBDA_API_URL ?? 'http://localhost:8080';
const staticPath = process.env.LAMBDA_STATIC_PATH ?? '';
const lambdaLogs = process.env.AWS_LAMBDA_FUNCTION_NAME ? true : false;
const labelsApi = process.env.LAMBDA_LABEL_API_URL ?? 'http://localhost:3001';

export { port, watchPort, useLocalApi, apiPath, proSonyApi, lambdaLogs, staticPath, labelsApi };
