/* global logger */

/**
 * @namespace helpers
 */

import { userAgent as UserAgent, isClient } from '../';

let dataLayer = {
    /**
     * @method dataLayer
     * @description Methods and polyfills for DataLayer custom events
     *
     * @memberof helpers
     *
     *
     * @property {object} data - Stores the DataLayer data for session cached access
     * @property {method} prepareCustomEvent - Prepares a polyfilled custom event
     * @property {method} CustomEvent - Used by `prepareCustomEvent` to polyfill custom event support
     * @property {method} update - Updates the DataLayer `data` object and fires `apply`
     * @property {method} triggerEvent - Dispatches the custom event to notify the remote data layer listeners that a change has been made
     * @property {method} apply - Applies the changes to the datalayer post `update` and fires `triggerEvent`
     *
     */
    data: {},
    prepareCustomEvent: function() {
        if (typeof window != 'undefined' && window.document) {
            // logger.info('src.helpers.dataLayer.js: Checking for custom events');

            if (typeof window.CustomEvent === 'function') return false;

            let CustomEvent = this.CustomEvent;

            CustomEvent.prototype = window.Event.prototype;

            window.CustomEvent = CustomEvent;
        }
    },
    CustomEvent: function(event, params) {
        params = params || { bubbles: false, cancelable: false, detail: undefined };
        let evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
    },
    update: function(
        metadata = {},
        entities = [],
        httpStatus,
        location = {},
        referrer,
        dataLayer = {},
        requestRelated = {}
    ) {
        /* carve out the data we need */

        /**
         * Group dataLayer properties
         */

        const country = dataLayer?.country;
        const product = dataLayer?.product;
        const categories = dataLayer?.categories;

        /**
         * Group metadata properties
         */

        const name = metadata?.logicalName;
        const isHero = metadata?.heroCape;

        /**
         * Group requestRelated
         */

        const code = requestRelated?.status;
        const search = requestRelated?.search;
        const user = requestRelated?.user;

        /**
         * Group location properties
         */

        const { query = {} } = location;
        const { cmp, CMP } = query;

        const trackingCode = cmp ? cmp : CMP;
        const pathName = location.pathname;
        const requestedUrl = pathName;

        /**
         * Group miscellaenous properties
         */

        const userAgent = UserAgent();
        const modules = entities;

        /* set the data object */

        this.data = {
            version: '1.2.0',
            page: {
                name,
                trackingCode,
                isHero,
                userAgent,
                modules,
                pathName,
                ...dataLayer
            },
            error: {
                code,
                requestedUrl,
                referringCountry: country
            },
            product,
            categories,
            user,
            search,
            requestRelated,
            location,
            debug: {
                httpStatus,
                metadata,
                entities,
                location,
                dataLayer,
                requestRelated,
                deprecated: {
                    referrer
                }
            }
        };

        // logger.info('src.helpers.dataLayer.js: Preparing data to send to data layer');

        this.apply();

        return true;
    },
    triggerEvent(eventName, props) {
        if (isClient()) {
            // logger.info(`src.helpers.dataLayer.js: Firing custom event: ${eventName}`);

            let event = new CustomEvent(eventName, {
                detail: props
            });
            document.dispatchEvent(event);
        }
        return;
    },
    apply: function() {
        // logger.info('src.helpers.dataLayer.js: Searching for data layer');

        if (typeof window != 'undefined' && window.document) {
            // logger.info('src.helpers.dataLayer.js: Found data layer target.');
            // logger.info('src.helpers.dataLayer.js: Applying...');

            window.DataLayerObject = this.data;

            window.SonyAnalytics = function() {
                return {
                    build: function() {
                        const analytics = window.DataLayerObject;

                        return { analytics };
                    }
                };
            };

            /* fire event */

            let event = new CustomEvent('page_state_updated');
            document.dispatchEvent(event);
        }
    }
};

export default dataLayer;