/**
 * @namespace helpers
 */

export default () => {
    /**
     * @method isClient
     * @description Returns true if the environment is a browser/userland and false if a node environment
     *
     * @memberof helpers
     *
     * @returns {boolean}
     *
     * @example
     * return isClient() ? true : false;
     */

    let isClient = false;
    if (typeof window !== 'undefined' && window.document) {
        isClient = true;
    }

    return isClient;
};
