import { port, watchPort } from '../../config/server';
import { local } from '../../config/client';
import { isClient } from '../';

/**
 * @todo DW [2020/02/11]: complete tests
 * @todo DW [2020/02/11]: complete docs
 */

export default () => {
    if (isClient() && !local) {
        // logger.info('src/helpers/getUrl/getUrl.js: is client and not local ');

        return `${window.location.protocol}//${window.location.hostname}${
            window.location.port ? `:${window.location.port}` : ''
        }`;
    } else {
        if (local) {
            // logger.info('src/helpers/getUrl/getUrl.js: is not client and local ');
            return `http://localhost:${watchPort}`;
        } else {
            console.log('src/helpers/getUrl/getUrl.js: is not client and not local ');
            return `http://localhost:${port}`;
        }
    }
};
