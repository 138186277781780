/**
 * @namespace helpers
 */

export default to => {
    /**
     * @method isExternal
     * @description Returns a boolean value indicating whether the provide `to` argument is an external link (http://google.com) or a relative link (/pro/home). **Note that any links containing `/pdf` will also be considered an external link due to a workaround patch for PDPs.**
     * @deprecated Will be retired when single page app functionality is disabled on Meerkat II.
     *
     * @memberof helpers
     *
     *
     * @param {string} to - A string (usually a full URI or path) to be checked for external protocols and matching strings
     *
     * @todo DW [2019/11/12]: Hotfix/patch for presence of `/pdf` needs to be reverted
     *
     * @returns {boolean}
     *
     * @example
     * return isExternal('http://google.com'); // => true
     */

    let isExternal = false;

    if (
        /^(f|ht)tps?:\/\//i.test(to.toLowerCase()) ||
        /^mailto:([^?]*)/.test(to.toLowerCase()) ||
        to.toLowerCase().includes('/pdf')
    ) {
        isExternal = true;
    }
    return isExternal;
};
