/* global logger */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import axios from 'axios';

import { fetchPage } from '../../actions/fetchPage';
import { fetchNavigation } from '../../actions/fetchNavigation';
import { fetchLabels } from '../../actions/fetchLabels';
import { getUrl, parseUrl, isClient } from '../../helpers';

import ComponentTree from '../ComponentTree/ComponentTree';
import Loader from '../../components/shared/Loader/Loader';
import pseLocales from '../../assets/locales/pseLocales.js';

const mapStateToProps = (state = {}) => ({
    ...state,
});

const mapDispatchToProps = dispatch => ({
    fetchPage: ({ uri, params, headers, search }) => dispatch(fetchPage({ uri, params, headers, search })),
    fetchNavigation: ({ uri, params, headers, search, type }) =>
        dispatch(fetchNavigation({ uri, params, headers, search, type })),
    fetchLabels: ({ locale, modules }) => dispatch(fetchLabels({ locale, modules })),
});

class App extends React.Component {
    constructor(props) {
        super(props);
        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {
        if (isClient()) {
            const { pathname = '' } = location;
            console.log('App');
            console.log('App Props:', JSON.stringify(this.props));
            console.log('App ENV:', process.env.NODE_ENV);
            if (typeof pathname === 'string') {
                console.log('App Path:', pathname);
                this.fetchData({ props: this.props });
            }
            const urlParser = new parseUrl();
            const parsedLocation = urlParser.parse(pathname);
            const { params = {} } = parsedLocation;
            const { locale = 'en_GB' } = params;
            if (!Cookies.get('hasFetchedDarkVisitors') && locale === 'en_GB') {
                axios.get(`${getUrl()}/api/visits`).catch(err => {
                    console.error("Dark Visitor Call Failed", err);
                })
                Cookies.set('hasFetchedDarkVisitors', 'true');
            }
        }
    }

    fetchData({ props }) {
        const { location = {}, page = {} } = props;
        const { pathname = '' } = location;

        const urlParser = new parseUrl();

        const parsedLocation = urlParser.parse(pathname);
        const { params = {}, search = '' } = parsedLocation;
        const { locale = 'en_GB' } = params;

        /**
         * Kick off our network requests
         */

        if (Object.entries(page).length === 0 && page.constructor === Object) {
            this.props
                .fetchPage({
                    uri: getUrl(),
                    params,
                    headers: {},
                    search,
                })
                .then((result = {}) => {
                    const { originalModules = [] } = result;

                    this.props.fetchLabels({
                        locale,
                        modules: originalModules,
                    });
                });

            this.props.fetchNavigation({
                uri: getUrl(),
                params,
                headers: {},
                search,
                type: 'navigation',
            });

            this.props.fetchNavigation({
                uri: getUrl(),
                params,
                headers: {},
                search,
                type: 'footer',
            });
        }
    }

    render() {
        const { loading } = this.props;

        return loading.labels || loading.page || loading.navigation || loading.footer ? (
            <Loader />
        ) : (
            <ComponentTree {...this.props} />
        );
    }
}

App.propTypes = {
    fetchNavigation: PropTypes.func,
    fetchLabels: PropTypes.func,
    /**
     * components
     */

    components: PropTypes.object.isRequired,

    /**
     * loading
     */

    loading: PropTypes.shape({
        page: PropTypes.bool,
        navigation: PropTypes.bool,
        footer: PropTypes.bool,
        labels: PropTypes.bool,
    }),

    /**
     * actions
     */

    fetchPage: PropTypes.func,

    /**
     * location
     */

    location: PropTypes.object,

    /**
     * page
     */

    page: PropTypes.shape({
        metadata: PropTypes.object,
        modules: PropTypes.array,
        requestRelated: PropTypes.object,
        dataLayer: PropTypes.object,
        httpStatus: PropTypes.number,
    }),

    /**
     * navigation
     */

    navigation: PropTypes.shape({
        navigation: PropTypes.object,
        footer: PropTypes.object,
    }),

    /**
     * labels
     */

    labels: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
