/* global logger */

/**
 * @namespace actions
 */

import request from '../http/request';
import { getUrlPath } from '../helpers';
import moment from 'moment';

export const fetchNavigation = ({ uri, params = {}, headers = {}, search, type = 'footer' }) => async dispatch => {
    /**
     * @method fetchNavigation
     * @description Async function to fetch and return page data to the reducer
     *
     * @memberof actions
     *
     *
     * @param {object} object
     * @param {string} object.uri - Domain from config used as the root of the request
     * @param {object} object.params - Params object containing pro.sony path parameters from parseUrl
     * @param {string} object.params.category
     * @param {string} object.params.subCategory
     * @param {string} object.params.topLevelCategory
     * @param {object} object.headers - Header object to be passed back to pro.sony
     * @param {string} object.search - The concatenated search parameters
     *
     * @returns {promise}
     *
     * @example
     * return this.props.fetchNavigation({
     *  uri,
     *  params,
     *  headers,
     *  search
     * });
     */

    const types = {
        navigation: 'navigationUrl',
        footer: 'footerUrl',
    };

    dispatch({
        type: 'LOADING',
        payload: {
            [type]: true,
        },
    });

    /**
     * Prepare the URL data
     */

    const urlData = getUrlPath({ uri, params, search });

    const fetchUrl = urlData[types[type] ? types[type] : 'footer'];

    /**
     * Initiate the network request
     */
    logger.info(
        'src.actions.fetchNavigation.js: Making navigation request for %s to %s at %s',
        type,
        fetchUrl,
        moment().toString()
    );

    logger.info('src.actions.fetchNavigation.js: Fetching navigation using headers: ' + JSON.stringify(headers));

    if (!fetchUrl) {
        dispatch({
            type: 'LOADING',
            payload: {
                [type]: false,
            },
        });

        return;
    }

    await request
        .get(fetchUrl)
        .set({ ...headers })
        .then(response => {
            let { body } = response;
            body.httpStatus = response.status;

            logger.info(
                'src.actions.fetchNavigation.js: Successfull navigation response for %s at ',
                type,
                moment().toString()
            );

            dispatch({
                type: 'NAVIGATION',
                payload: {
                    [type]: body,
                },
            });

            dispatch({
                type: 'LOADING',
                payload: {
                    [type]: false,
                },
            });
        })
        .catch(error => {
            logger.error(
                'src.routes.api.fetchNavigation.js: error fetching from remote API for ' + fetchUrl + ' %s: %s',
                type,
                error
            );
        });
};
