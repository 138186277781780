/* global logger */

/**
 * @namespace core
 */

import React from 'react';
import ErrorBoundary from '../containers/ErrorBoundary/ErrorBoundary';

export default ({ modules = [], extraProps = {}, UiComponents = {} }) => {
    /**
     * @method componentComposer
     * @description Accepts an array of modules from pro.sony API and renders the components. Compatible with loadable components.
     *
     * @memberof core
     *
     *
     * @param {array} modules - Module array provided from pro.sony.
     * @param {object} extraProps - Extra properties to be passed to each composed component.
     * @param {object} UiComponents - Imported React components. Supports loadables.
     *
     * @returns {array} - An array of components that can then be directly rendered.
     *
     * @example
     * const ComposedComponents = componentComposer({
     *  modules,
     *  extraProps,
     *  UiComponents
     * });
     *
     * return ComposedComponents;
     */

    const modulesArray = [];
    let previousModule = null;

    modules.map((module, i) => {
        let ComponentName = module.name;

        /* check if the component exists in the array */

        if (UiComponents[ComponentName]) {
            /* compose component */
            let ComposedComponent = UiComponents[ComponentName];

            /* push it onto the end of the modules array */
            modulesArray.push(
                <ErrorBoundary key={i}>
                    <ComposedComponent
                        {...module}
                        {...extraProps}
                        components={ComponentName === 'Glue' ? UiComponents : {}}
                        previousModule={previousModule}
                    />
                </ErrorBoundary>
            );

            previousModule = ComponentName;
        } else {
            logger.warn(
                'src.core.componentComposer.js: Component composition failure. Failed to compose component from module: %s. Skipping...',
                ComponentName
            );
        }

        return true;
    });

    return modulesArray;
};
