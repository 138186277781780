import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Route, Redirect } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';

import { isClient } from './helpers';
import createLogger from './core/createLogger';
import configureStore from './stores/store';
import App from './containers/App/App';
import * as UiComponents from './components';

import 'font-awesome/css/font-awesome.min.css';

import './sass/index.scss';

global.logger = global.logger ? global.logger : createLogger({ service: 'meerkat' });

const history = createHistory();

const initialState = (isClient() && window.__PRELOADED_STATE__) || {};

const AppLoader = (
    <Provider store={configureStore({ initialState, history })}>
        <Router history={history}>
            <Route exact path="/" component={() => <Redirect to="/home" />} />
            <Route path="*" component={props => <App components={UiComponents} {...props} />} />
        </Router>
    </Provider>
);

global.hydrate
    ? (window.onload = () => {
          ReactDOM.hydrate(AppLoader, document.getElementById('root'));
      })
    : ReactDOM.render(AppLoader, document.getElementById('root'));

// return user back to where they scrolled to when clicking browser back/forward button
(() => {
    // save pathName and scrollPosition to sessionStorage
    const pathName = document.location.pathname;
    window.onbeforeunload = () => {
        const scrollPosition = window.pageYOffset.toFixed();
        sessionStorage.setItem('scrollPosition_' + pathName, scrollPosition.toString());
    };

    const transportUser = () => {
        // get sessionStorage values
        if (sessionStorage['scrollPosition_' + pathName]) {
            // addition for Meerkat II - needs to be inside 'setTimeout'
            setTimeout(() => {
                document.documentElement.scrollTop = sessionStorage.getItem('scrollPosition_' + pathName);
                console.log(
                    'User got here from Back or Forward browser button; transporting user to scrollPosition: ' +
                        document.documentElement.scrollTop
                );
            }, 1000);
        }
    };

    // detect only back/forward button use; ignore if user refresh or new URL
    const perfEntries = performance.getEntriesByType('navigation');
    // performance.getEntriesByType() works on Chrome/Firefox/Edge
    if (perfEntries.length && perfEntries[0].type === 'back_forward') {
        transportUser();
    }
    // window.onpageshow() event.persisted works on Safari
    window.onpageshow = event => {
        if (event.persisted) {
            transportUser();
        }
    };
})();
