/* global logger */

/**
 * @todo DW [2020/02/11]: complete tests
 * @todo DW [2020/02/11]: complete docs
 */

export default ({ uri, params = {}, search = '', debug = false }) => {
    const { locale = 'en_GB' } = params;

    const logicalName = params.logicalName && params.logicalName !== '' ? params.logicalName : 'home';

    /**
     * gather the other (potential) URL params
     * we need these primarily for the navigation fetch (for now)
     */
    if (debug) {
        logger.info('src.helpers.getUrlPath.js: Locale: %s', locale);
    }

    let category = params.category ? `${params.category}/` : '';
    let subCategory = params.subCategory ? `${params.subCategory}/` : '';
    let topLevelCategory = params.topLevelCategory ? `${params.topLevelCategory}/` : '';

    let pathString = `/${locale}/api/dynamicmodules/${topLevelCategory}${category}${subCategory}${logicalName}${search}`;

    let contentUrl = uri + pathString;

    let navigationElements = {
        mainNavigation: 'navigation-bar',
        breadcrumb: 'breadcrumb',
        footer: 'footer-bar',
    };

    /**
     * navigationUrl must have a trailing slash for express to understand the route.
     * without the slash, express does not understand the succeeding optional URL params
     */
    let navigationUrl = uri + `/${locale}/api/navigation/${navigationElements.mainNavigation}`;
    let breadcrumbUrl =
        uri +
        `/${locale}/api/navigation/${navigationElements.breadcrumb}/${topLevelCategory}${category}${subCategory}${logicalName}`;
    let footerUrl = uri + `/${locale}/api/navigation/${navigationElements.footer}`;

    return {
        contentUrl,
        navigationUrl,
        breadcrumbUrl,
        footerUrl,
    };
};
