import { currentLocale } from '../../../../helpers';

function cookieHandler() {
    const setMyCookie = (cname, cvalue, exdays) => {
        const date = new Date();
        date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000);
        const expires = `expires=${date.toUTCString()}`;
        document.cookie = `${cname}=${cvalue}; ${expires}; path=/;`;
    };
    const getMyCookie = cname => {
        if (!cname && cname !== 0) return null;
        cname = encodeURIComponent(cname).replace(/[.*+?^=!:${}()|[\]\/\\]/g, '\\$&'); //escape regexp characters
        const rxp = new RegExp('(?:^|;)\\s*' + cname + '=([^;]*)');
        const theCookie = rxp.exec(document.cookie);
        return theCookie ? decodeURIComponent(theCookie[1]) : null;
    };

    const compareCookie = () => {
        const localeFromDataLayer = currentLocale.get();
        const localeFromCookie = getMyCookie('geofilter_country_selected');
        const destinationCookie = getMyCookie('geofilter_country');
        if (localeFromDataLayer && localeFromDataLayer.includes(destinationCookie)) {
            return false;
        }
        if (localeFromDataLayer === localeFromCookie) {
            return false;
        } else {
            if (!(getMyCookie('geofilter_country_selected') || getMyCookie('geofilter_show_popup'))) {
                return true;
            }
            if (getMyCookie('geofilter_show_popup')) {
                return false;
            } else {
                if (getMyCookie('geofilter_country_selected') !== localeFromDataLayer) {
                    return true;
                }
            }
        }
    };
    return { setMyCookie, getMyCookie, compareCookie };
}

export default cookieHandler;
