/* global logger */

/**
 * @namespace containers
 */

import React from 'react';
import PropTypes from 'prop-types';

import './ErrorBoundary.scss';

/**
 * @class ErrorBoundary
 *
 * ErrorBoundary component that quietly handles catastrophic failures at the composed component level, hides the output and squirts a small error message into an HTML comment
 *
 * @memberof containers
 *
 *
 * @property {object} children - Any valid react child element or component
 *
 * @example
 * return (
 *   <ErrorBoundary><<MyComponent /></ErrorBoundary>
 * )
 */

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        logger.error(error, errorInfo);
    }

    render() {
        const { hasError, error } = this.state;
        const { children } = this.props;

        return hasError ? (
            <div
                className="error-boundary"
                dangerouslySetInnerHTML={{
                    __html: `<!-- component failed to load, caught by ErrorBoundary | ${error}-->`,
                }}
            />
        ) : (
            children
        );
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.any,
};

export default ErrorBoundary;
