/**
 * @namespace helpers
 */

export default () => {
    /**
     * @method userAgent
     * @description Returns the user agent string of the browser or client
     *
     * @memberof helpers
     *
     * @returns {string}
     *
     * @example
     * return userAgent(); // => Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_6) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0 Safari/605.1.15
     *
     */

    let agent = null;

    if (typeof navigator !== 'undefined' && navigator) {
        agent = navigator.userAgent;
    }

    return agent;
};
