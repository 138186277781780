import React from 'react';

import miniGazelle from '../miniGazelle/miniGazelle';

export default function(i, currentSlide) {
    const print = i + 1;

    return (
        <button>
            {currentSlide === i ? miniGazelle.label('currentlyOnScreen', true) : null}{' '}
            {`${miniGazelle.label('slide', true)}: ${print}`}
        </button>
    );
}
