/* global logger */

/**
 * @namespace helpers
 */

import { isClient } from '../';

export default ({ locale, currentPath, dom }) => {
    /**
     * @method setCookie
     * @description Sets the locale and lastPath cookies used by pro.sony on the client for redirection and locale detection. Typically fired at every render.
     *
     * @memberof helpers
     *
     *
     * @param {string} locale - The clients currently selected locale to be remembered
     * @param {string} currentPath - The current path of the user. As this is only set on UI render, it can be used before render time to send the user back to this page e.g. post redirection
     * @param {object} dom - Allows the document object to be overridden for testing or non-browser environments. Defaults to `document` if undefined
     *
     * @returns {null} - Returns nothing, but sets two cookies on the client document
     *
     * @example
     * setCookie(localisationData.locale, localisationData.currentPath);
     */

    if (isClient()) {
        dom = dom ? dom : document;

        logger.info(`src.helpers.setCookie.js: Setting cookie for locale: ${locale} and ${currentPath}`);

        let d = new Date();
        d.setTime(d.getTime() + 1 * 24 * 30 * 60 * 60 * 1000);

        const expires = d.toUTCString();

        dom.cookie = `locale=${locale}; expires=${expires}; path=/`;
        dom.cookie = `lastPath=${currentPath}; expires=${expires}; path=/`;
    }
};
