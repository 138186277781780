/* global logger */

/**
 * @namespace helpers
 */

import { isClient } from '../';
import md5 from 'md5';

/**
 * @method scriptManager
 * @description Manages dynamically inserted script and css includes for RichMedia
 *
 * @memberof helpers
 *
 *
 * @property {method} map - injects scripts specific to the logicalName of the given module
 * @property {method} remove - removes scripts specific to the logical name of the given module
 *
 */

const ScriptManager = {
    map: function({ props = {}, dom }) {
        if (isClient()) {
            dom = dom ? dom : document;

            const { data = {} } = props;
            const { logicalName } = data;

            const scripts = data.scripts || [];
            const styles = data.styles || [];

            this.remove({ logicalName, dom });

            scripts.map(script => {
                /* for each script we're going to create an md5 hash to track it over multiple component loads/unloads */

                const hash = md5(script.js);

                const scriptExists = dom.querySelector(`script[data-rm-hash="${hash}"]`);

                if (!scriptExists) {
                    logger.info(`src.helpers.scriptManager.js: No script found at ${hash}, inserting`);

                    const newScript = dom.createElement('script');

                    newScript.type = 'text/javascript';
                    newScript.src = script.js;
                    newScript.async = script.async;
                    newScript.setAttribute(`data-rm-dynamic-script-${logicalName}`, 'true');
                    newScript.setAttribute('data-rm-hash', hash);

                    dom.body.appendChild(newScript);
                } else {
                    logger.info(`Script collision at ${hash}, skipping insert`);
                }

                return true;
            });

            styles.map(style => {
                /* for each style we're going to create an md5 hash to track it over multiple component loads/unloads */

                const hash = md5(style.css);

                const styleExists = dom.querySelector(`link[data-rm-hash="${hash}"]`);

                if (!styleExists) {
                    logger.info(`No style found at ${hash}, inserting`);

                    const newStyle = dom.createElement('link');

                    newStyle.setAttribute('rel', 'stylesheet');
                    newStyle.setAttribute('type', 'text/css');
                    newStyle.setAttribute('href', style.css);
                    newStyle.setAttribute(`data-rm-dynamic-style-${logicalName}`, 'true');
                    newStyle.setAttribute('data-rm-hash', hash);

                    const head = dom.getElementsByTagName('head')[0];

                    head.insertBefore(newStyle, head.firstChild);
                } else {
                    logger.info(`Style collision at ${hash}, skipping insert`);
                }

                return true;
            });
        }
    },
    remove: function({ logicalName = '', dom }) {
        if (isClient()) {
            /* remove all old scripts first */

            dom = dom ? dom : document;

            const oldScripts = dom.querySelectorAll(`data-rm-dynamic-script-${logicalName}`);

            if (oldScripts.length > 0) {
                Array.prototype.forEach.call(oldScripts, function(script) {
                    script.remove();
                });
            }

            const oldStyles = dom.querySelectorAll(`data-rm-dynamic-style-${logicalName}`);

            if (oldStyles.length > 0) {
                Array.prototype.forEach.call(oldStyles, function(style) {
                    style.remove();
                });
            }
        }
    }
};

export default ScriptManager;
