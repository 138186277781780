import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import request from 'superagent';
import { dataLayer, currentLocale, geoLocationApi } from '../../../helpers';
import cookieBannerLocales from './helpers/cookieBannerLocales';
import LocationBanner from './common/LocationBanner';
import cookieHandler from './helpers/cookieHandler';
import './GeoLocation.scss';

const GeoLocation = () => {
    const [destinationUrl, setDestinationUrl] = useState(false);
    const [destinationLabel, setDestinationLabel] = useState(false);
    const [destinationLocale, setDestinationLocale] = useState('en_GB');
    const [bannerDismissed, setBannerDismissed] = useState(false);
    const [documentLoaded, setDocumentLoaded] = useState(false);
    const [evidonAccepted, setEvidonAccepted] = useState(false);
    const [data, setData] = useState({});

    const handleChange = event => {
        const value = event?.target?.value;
        const label = event?.target[event.target.selectedIndex]?.id;
        setDestinationUrl(value);
        setDestinationLabel(label);
    };

    const { setMyCookie, getMyCookie, compareCookie } = cookieHandler();

    const checkEvidon = () => {
        const localeFromDataLayer = currentLocale.get();
        if (cookieBannerLocales.includes(localeFromDataLayer)) {
            return evidonAccepted;
        } else {
            return true;
        }
    };

    const customEventHandler = ({ name, details }) => {
        dataLayer.triggerEvent(name, details);
        return true;
    };

    const handleContinueClicked = () => {
        setMyCookie('geofilter_country_selected', destinationLocale, 90);
        window.location.href = destinationUrl;
        customEventHandler({
            name: 'location_banner_continue',
            details: {
                'User interaction': 'Click on Continue',
                'Event label': destinationLabel,
            },
        });
    };

    const handleDismissClicked = () => {
        setMyCookie('geofilter_show_popup', 'false');
        setBannerDismissed(true);
        customEventHandler({
            name: 'location_banner_dismiss',
            details: {
                'User interaction': 'Click on Dismiss',
                'Event label': destinationLabel,
            },
        });
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const localeFromDataLayer = currentLocale.get();
                const destinationCookie = getMyCookie('geofilter_country');
                const uri = geoLocationApi();
                const hostname = location.hostname;

                if (localeFromDataLayer && localeFromDataLayer.includes(destinationCookie)) {
                    setDocumentLoaded(false);
                } else {
                    const res = await request.get(uri);
                    const { body } = res;
                    const { data = {} } = body;
                    setData(data);
                    setDocumentLoaded(true);
                    setDestinationLocale(data?.destinationLocale);
                    setDestinationUrl(data?.links[0]?.link);
                    setDestinationLabel(data?.links[0]?.title);
                }
            } catch (error) {
                console.warn('Error fetching data', error);
            }
        };

        fetchData();
        const evidonAcceptedListener = event => {
            setEvidonAccepted(event.detail);
        };
        document.addEventListener('evidon_accepted', evidonAcceptedListener, false);
        return () => {
            document.removeEventListener('evidon_accepted', evidonAcceptedListener, false);
        };
    }, []);

    const { label, links = [] } = data;
    const options = links.map(item => ({
        value: item.link,
        label: item.title,
    }));
    const optionsMarkup = options.map((option, i) => {
        return (
            <option key={i} id={option.label} value={option.value} defaultValue={option[0]}>
                {option.label}
            </option>
        );
    });

    return (
        <div
            className={classNames({
                'geo-dismissed': bannerDismissed === true,
                'geo-show': bannerDismissed === false || evidonAccepted !== false,
            })}
        >
            {checkEvidon() && compareCookie() && documentLoaded && (
                <LocationBanner
                    label={label}
                    optionsMarkup={optionsMarkup}
                    handleChange={handleChange}
                    handleContinueClicked={handleContinueClicked}
                    handleDismissClicked={handleDismissClicked}
                />
            )}
        </div>
    );
};

export default GeoLocation;
