/**
 * @namespace helpers
 */

/**
 * @method currentLocale
 * @description Getter and setter for currentLocale used to persist locale setting for the client lifecycle
 *
 * @memberof helpers
 *
 *
 * @returns {string}
 *
 * @example
 * currentLocale.set('foo');
 * currentLocale.get(); // => 'foo'
 */

export default {
    currentLocale: null,
    get: function() {
        return this.currentLocale;
    },
    set: function(locale) {
        this.currentLocale = locale;
        return;
    }
};
