/**
 * @namespace helpers
 */

import { useLocalApi, apiPath } from '../../config/server';

export default () => {
    /**
     * @method apiUrl
     * @description Checks the configuration variable and retruns a variable depending on whether we are local or deployed
     *
     * @memberof helpers
     *
     * @returns {string}
     *
     * @example
     * return apiUrl();
     */

    return useLocalApi ? `${apiPath}/modules/` : apiPath;
};
