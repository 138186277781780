/**
 * @namespace helpers
 */

/**
 * @method passwordTest
 * @description Password testing code that matches c-link expectations. Shamelessly stolen from sony.co.uk bit for bit.
 *
 * @memberof helpers
 * @author Some Consumer Dude
 *
 * @property {array} dictionary - full list of banned dictionary words for frontend validation
 * @property {integer} minLength - minimum enforced length of password string
 * @property {integer} strength - minimum acceptable level of calculated password strength
 * @property {object} tests
 * @property {method} tests.length - calculates a score against string and required length and returns a score
 * @property {method} tests.numericOrSpecial - calculates and returns a positive score if enough numeric and special characters are present in the string
 * @property {method} tests.localEmail - returns a positive score if the local part of the users email is not present in the given string
 * @property {method} tests.dictionaryCheck - returns a positive score if the password cannot be found in the `dictionary` array
 * @property {method} tests.continuous - returns a positive score if there are no continuous characters in the given string
 * @property {method} tests.sequence - returns a positive score if there are no predictable sequences in the string, e.g. `abcd` or `1234`
 * @property {method} tests.calculateScore - executes all tests and returns the score
 * @property {method} tests.checkPassword - main method that passes the password and email to the calculation method `calculateScore`
 *
 * @returns {boolean}
 *
 * @example
 * passwordTest.checkPassword('sony', 'Darryl.Walker@sony.com'); // => false
 * passwordTest.checkPassword('CorrectHorseBatteryStaple1989', 'Darryl.Walker@sony.com'); // => true
 *
 */

let PasswordTest = {
    dictionary: [
        '!QAZ2wsx',
        '!QAZxsw2',
        'a1234567',
        'a1b2c3d4',
        'aaaaaaa1',
        'abc12345',
        'abcd1234',
        'abcdefg1',
        'access14',
        'access99',
        'admin123',
        'allison1',
        'alpha123',
        'america1',
        'andyod22',
        'anthony1',
        'anthony7',
        'apollo13',
        'apple123',
        'arizona1',
        'arsenal1',
        'asdf1234',
        'asshole1',
        'austin31',
        'b929ezzh',
        'babylon5',
        'basebal1',
        'beatles1',
        'bigdick1',
        'blink182',
        'blue1234',
        'brandon1',
        'broncos1',
        'bubba123',
        'bubbles1',
        'buddy123',
        'buffalo1',
        'bulldog1',
        'cameron1',
        'captain1',
        'care1839',
        'cartman1',
        'cbr900rr',
        'cezer121',
        'charles1',
        'charlie1',
        'charlie2',
        'chelsea1',
        'chester1',
        'chicago1',
        'chicken1',
        'chris123',
        'claudia1',
        'corvet07',
        'cowboys1',
        'cricket1',
        'crystal1',
        'csfbr5yy',
        'cygnusx1',
        'dad2ownu',
        'dapzu455',
        'death666',
        'deluxe33',
        'destiny1',
        'devil666',
        'diamond1',
        'digital1',
        'dilbert1',
        'dodgers1',
        'dolphin1',
        'dragon12',
        'dragon69',
        'dreamer1',
        'drummer1',
        'eclipse1',
        'england1',
        'express1',
        'f00tball',
        'ferrari1',
        'ffvdj474',
        'fishing1',
        'florida1',
        'flyers88',
        'fordf150',
        'formula1',
        'frankie1',
        'freedom1',
        'front242',
        'fuckoff1',
        'fuckyou1',
        'fuckyou2',
        'gabriel1',
        'gandalf1',
        'gateway1',
        'gateway2',
        'general1',
        'genesis1',
        'gfxqx686',
        'gizmodo1',
        'gordon24',
        'green123',
        'gregory1',
        'gsxr1000',
        'happy123',
        'hawaii50',
        'heather1',
        'hello123',
        'hihje863',
        'hooters1',
        'hotmail0',
        'hotmail1',
        'houston1',
        'huskers1',
        'hzze929b',
        'iqzzt580',
        'isacs155',
        'iverson3',
        'jackass1',
        'jackson1',
        'jackson5',
        'james007',
        'jasmine1',
        'jessica1',
        'jo9k2jw2',
        'johnson1',
        'jordan23',
        'jupiter1',
        'jupiter2',
        'just4fun',
        'kcj9wx5n',
        'kordell1',
        'kristin1',
        'letmein1',
        'letmein2',
        'lincoln1',
        'luv2epus',
        'madison1',
        'marcius2',
        'marines1',
        'marino13',
        'mash4077',
        'master12',
        'matthew1',
        'maxwell1',
        'mazda626',
        'mazdarx7',
        'melanie1',
        'melissa1',
        'mercury1',
        'michael1',
        'michael2',
        'misfit99',
        'money123',
        'monkey12',
        'monster1',
        'montana1',
        'mounta1n',
        'mustang1',
        'mustang2',
        'mustang5',
        'mustang6',
        'mwq6qlzo',
        'myspace1',
        'nancy123',
        'nascar24',
        'natalie1',
        'natasha1',
        'ncc1701a',
        'ncc1701d',
        'ncc1701e',
        'ncc74656',
        'nemrac58',
        'newpass6',
        'newyork1',
        'nirvana1',
        'nwo4life',
        'ozlq6qwm',
        'P@ssw0rd',
        'Pa$$w0rd',
        'pa55w0rd',
        'pa55word',
        'packers1',
        'paladin1',
        'pantera1',
        'panther1',
        'pass1234',
        'passw0rd',
        'passwor1',
        'patches1',
        'patrick1',
        'peaches1',
        'penguin1',
        'perfect1',
        'phantom1',
        'phoenix1',
        'playboy1',
        'playboy2',
        'pool6123',
        'porsche1',
        'porsche9',
        'prelude1',
        'private1',
        'ptfe3xxp',
        'pumpkin1',
        'pussy123',
        'pussy4me',
        'pxx3eftp',
        'q1w2e3r4',
        'qcmfd454',
        'qwer1234',
        'qwert123',
        'qwerty12',
        'r2d2c3po',
        'raiders1',
        'rainbow1',
        'rainbow6',
        'rangers1',
        'rasta220',
        'rebecca1',
        'richard1',
        'root1234',
        'rt6ytere',
        'rush2112',
        'russell1',
        'sabrina1',
        'samsung1',
        'sanity72',
        'satan666',
        'save13tx',
        'scooter1',
        'scorpio1',
        'shadow12',
        'shannon1',
        'skeeter1',
        'skipper1',
        'soccer10',
        'soccer11',
        'soccer12',
        'sooners1',
        'spartan1',
        'special1',
        'spencer1',
        'ssptx452',
        'stanley1',
        'stephen1',
        'stewart1',
        'success1',
        'summer69',
        'summer99',
        'test1234',
        'testing1',
        'therock1',
        'thumper1',
        'thunder1',
        'tiffany1',
        'tiger123',
        'tmjxn151',
        'trinity1',
        'trooper1',
        'trouble1',
        'trumpet1',
        'trustno1',
        'turkey50',
        'vampire1',
        'vanessa1',
        'vikings1',
        'vincent1',
        'voyager1',
        'warrior1',
        'welcome1',
        'wg8e3wjf',
        'william1',
        'windows1',
        'winston1',
        'winter99',
        'wp2003wp',
        'wrinkle1',
        'wrinkle5',
        'xxxxxxx1',
        'yamahar1',
        'yankees1',
        'yankees2',
        'year2005',
        'yqlgr667',
        'yvtte545',
        'yy5rbfsc',
        'zachary1',
        'ZAQ!2wsx',
        'ZAQ!xsw2',
        'zaq1@WSX',
        'zaq12wsx',
        'zaq1XSW@',
        'zaq1xsw2',
        'zxcvbnm1',
        'abc123456',
        'baseball1',
        'birthday1',
        'birthday4',
        'fatluvr69',
        'favorite2',
        'favorite6',
        'football1',
        'fortune12',
        'gnasher23',
        'iloveyou1',
        'iloveyou2',
        'letmein22',
        'passwd123',
        'password1',
        'password2',
        'password9',
        'porn4life',
        'qwerty123',
        'slimed123',
        'charlie123',
        'password12',
        'postov1000',
        'q1w2e3r4t5',
        'quant4307s',
        'iamhacker22',
        'password123',
        'primetime21'
    ],
    /* thresholds */
    minLength: 7,
    strength: 6,
    tests: {
        length: function(password, minLength) {
            let score = password.length > minLength ? 1 : 0;
            return score;
        },
        numericOrSpecial: function(password) {
            let score = password.match(/([0-9])/) || password.match(/([!,@,#,$,%,^,&,*,?,_,~,-,+,.])/) ? 1 : 0;
            return score;
        },
        localEmail: function(password, email) {
            let score = 1;
            email = email && email.length > 0 ? email : '';
            if (password.length > 0) {
                if (
                    email.length > 0 &&
                    /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(
                        email
                    )
                ) {
                    let idBeforeAt = email.split('@')[0];

                    if (password.indexOf(idBeforeAt) !== -1) {
                        score--;
                        if (password === idBeforeAt || password === email) {
                            score--;
                        }
                    }
                }
            }

            let finalScore = score === 1 ? score : 0;
            return finalScore;
        },
        dictionaryCheck: function(password, dictionary) {
            let score = 1;
            if (password.length > 0) {
                if (dictionary.indexOf(password) !== -1) {
                    score--;
                }
            }
            return score;
        },
        continuous: function(password) {
            let score = 1;

            if (password.length > 2) {
                password = password.split('');
                for (let k = 0, len = password.length; k < len - 2; k++) {
                    if (password[k] === password[k + 1] && password[k] === password[k + 2]) {
                        score--;
                        if (password.length > 3 && password[k] === password[k + 3]) {
                            score--;
                        }
                    }
                }
            }

            let finalScore = score === 1 ? score : 0;
            return finalScore;
        },
        sequence: function(password) {
            password = password.toLowerCase();
            let score = 1;
            if (password.length > 2) {
                let sequences = [
                    'abcdefghijklmnopqrstuvwxyz|',
                    'ABCDEFGHIJKLMNOPQRSTUVWXYZ|',
                    '0123456789|',
                    '0987654321|',
                    'ZYXWVUTSRQPONMLKJIHGFEDCBA|',
                    'zyxwvutsrqponmlkjihgfedcba|'
                ];
                for (let k = 0, len = sequences.length; k < len; k++) {
                    let pattern = sequences[k].split('');
                    for (let l = 0, slen = pattern.length; l < slen - 3; l++) {
                        if (password.indexOf(pattern[l] + pattern[l + 1] + pattern[l + 2]) !== -1) {
                            score--;
                            if (
                                password.length > 3 &&
                                password.indexOf(pattern[l] + pattern[l + 1] + pattern[l + 2] + pattern[l + 3]) !== -1
                            ) {
                                score--;
                            }
                        }
                    }
                }
            }

            let finalScore = score === 1 ? score : 0;
            return finalScore;
        }
    },
    calculateScore: function(password, email) {
        let tests = this.tests;
        let numbers = [
            tests.length(password, this.minLength),
            tests.numericOrSpecial(password),
            tests.localEmail(password, email),
            tests.dictionaryCheck(password, this.dictionary),
            tests.continuous(password),
            tests.sequence(password)
        ];
        let sum = numbers.reduce((a, b) => a + b, 0);
        return sum;
    },
    checkPassword(password, email) {
        password = password ? password : '';
        let pass = this.calculateScore(password, email) >= 6 ? true : false;
        return pass;
    }
};

export default PasswordTest;
