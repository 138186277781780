import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';

import rootReducer from '../reducers/rootReducer';
import { isClient } from '../helpers';

export default function configureStore({ initialState = {}, history }) {
    return isClient()
        ? createStore(
              rootReducer,
              initialState,
              compose(
                  applyMiddleware(thunk, routerMiddleware(history)),
                  isClient() && window.navigator.userAgent.includes('Chrome') && window.__REDUX_DEVTOOLS_EXTENSION__
                      ? window.__REDUX_DEVTOOLS_EXTENSION__()
                      : compose
              )
          )
        : createStore(rootReducer, initialState, compose(applyMiddleware(thunk)));
}
