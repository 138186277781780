export default (
    state = {
        navigation: {},
        footer: {},
        copyright: {},
        country: {}
    },
    action
) => {
    switch (action.type) {
        case 'NAVIGATION':
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};
