import React from 'react';
import classNames from 'classnames';

export default function LocationBanner({
    label,
    optionsMarkup,
    handleChange,
    handleContinueClicked,
    handleDismissClicked,
}) {
    return (
        <div className="container-fluid geo-location">
            <div className="container">
                <div className="row smort-center">
                    <div className="col-sm-5">
                        <label htmlFor="geographical-locator" className="geo-welcome">
                            {label}
                        </label>
                    </div>
                    <div className="col-sm-3">
                        <label className="sr-only">Select your country or region</label>
                        <form className="selector">
                            <select id="geographical-locator" className="location-select" onChange={handleChange}>
                                {optionsMarkup}
                            </select>
                        </form>
                    </div>
                    <div className="col-sm-4">
                        <div className="button-group">
                            <button
                                className={classNames({
                                    btn: true,
                                    'btn-lg': true,
                                    'btn-wide': true,
                                    'btn-yellow': true,
                                })}
                                onClick={handleContinueClicked}
                            >
                                Continue
                            </button>
                            <button
                                className={classNames({
                                    btn: true,
                                    'btn-lg': true,
                                    'btn-wide': true,
                                    'btn-default': true,
                                })}
                                onClick={handleDismissClicked}
                            >
                                Dismiss
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
