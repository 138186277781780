import request from '../http/request';
import { getUrlPath } from '../helpers';
import { fromJS } from 'immutable';

export const fetchPage = ({ uri, params = {}, headers = {}, search }) => async dispatch => {
    dispatch({ type: 'LOADING', payload: { page: true } });

    logger.info('src.actions.fetchPage.js: params are here %s', params);

    const urlData = getUrlPath({ uri, params, search });
    const { contentUrl } = urlData;

    /**
     * Initiate the network request
     */
    logger.info('src.actions.fetchPage.js: Fetching page from %s', contentUrl);
    logger.info('src.actions.fetchPage.js: Fetching page using headers: ' + JSON.stringify(headers));

    try {
        const response = await request.get(contentUrl).set({ ...headers });
        logger.info('src.actions.fetchPage.js: Success fetching page');

        const { body } = response;

        const modules = fromJS(body.modules || []).toJS();
        const originalModules = [...modules];

        let glueModules = [];

        if (modules && modules.length > 0) {
            modules.filter((module = {}, index) => {
                const { name } = module;

                if (name === 'Glue') {
                    glueModules.push({ index, module });
                    return true;
                } else {
                    return false;
                }
            });

            if (glueModules.length > 0) {
                glueModules = glueModules.reverse();

                glueModules.map((glueModule = {}) => {
                    const { index, module = {} } = glueModule;
                    const { data = {} } = module;
                    const { moduleCount } = data;

                    if (moduleCount) {
                        const removedModules = modules.splice(index + 1, moduleCount);

                        modules[index].data.modules = removedModules;
                        return true;
                    } else {
                        return false;
                    }
                });
            }
        }

        dispatch({ type: 'PAGE', payload: { ...body, modules, originalModules } });
    } catch (error) {
        logger.error('src.routes.api.fetchPage.js: Error fetching from remote API', contentUrl, error);
        const errorModules = fromJS(error.response?.body?.modules || []).toJS();
        const errorBody = error.response?.body || {};
        dispatch({ type: 'PAGE', payload: { ...errorBody, modules: errorModules } });
    } finally {
        dispatch({ type: 'LOADING', payload: { page: false } });
    }
};
