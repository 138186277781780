/**
 * @namespace core
 */

import { createLogger, format, transports } from 'winston';
import { isClient } from '../helpers';
import { lambdaLogs } from '../config/server';

const logger = ({ service = 'default' }) => {
    /**
     * @method logger
     * @description Logger constructor for server side logs and `STDOUT` and client side `console`
     *
     * @memberof core
     *
     *
     * @param {string} service - Service name that corresponds to output logs server side
     *
     * @todo DW [2019/11/13]: Strip console logging in production bundles
     *
     * @returns {function}
     *
     * @example
     * const logger = createLogger({ service: 'meerkat-lambda' });
     * logger.info('src.lambda.render.js: Configured logger');
     */

    /**
     * If the environment is on the client we need to fallback to the browser console
     */

    const logger =
        isClient() || lambdaLogs
            ? console
            : createLogger({
                  level: 'info',
                  format: format.combine(
                      format.timestamp({
                          format: 'YYYY-MM-DD HH:mm:ss'
                      }),
                      format.errors({ stack: true }),
                      format.splat(),
                      format.json()
                  ),
                  defaultMeta: { service },
                  transports: [
                      //
                      // - Write to all logs with level `info` and below to `combined.log`.
                      // - Write all logs error (and below) to `error.log`.
                      //
                      new transports.File({
                          filename: `${process.cwd()}/logs/${service}-error.log`,
                          level: 'error'
                      }),
                      new transports.File({
                          filename: `${process.cwd()}/logs/${service}-combined.log`
                      })
                  ]
              });

    //
    // If we're not in production then **ALSO** log to the `console`
    // with the colorized simple format.
    //
    if (process.env.NODE_ENV !== 'production' && !isClient() && !lambdaLogs) {
        logger.add(
            new transports.Console({
                format: format.combine(format.colorize(), format.simple())
            })
        );
    }

    return logger;
};

export default logger;
