const labelLanguages = [
    'en_GB',
    'ue_US',
    'en_MF',
    'en_LA',
    'en_CA',
    'en_AP',
    'en_AZ',
    'en_IN',
    'de_DE',
    'es_ES',
    'fr_FR',
    'it_IT',
    'pl_PL',
    'ru_RU',
    'tr_TR',
    'ls_LA',
    'bp_BR',
    'qf_CA',
    'ko_KR',
    'zh_CN',
];

const supportedLocales = {
    en_AE: 'en_MF',
    en_AO: 'en_MF',
    en_BF: 'en_MF',
    en_BI: 'en_MF',
    en_BJ: 'en_MF',
    en_BW: 'en_MF',
    en_CF: 'en_MF',
    en_CG: 'en_MF',
    en_CI: 'en_MF',
    en_CM: 'en_MF',
    en_CV: 'en_MF',
    en_DJ: 'en_MF',
    en_DZ: 'en_MF',
    en_EG: 'en_MF',
    en_EH: 'en_MF',
    en_ER: 'en_MF',
    en_ET: 'en_MF',
    en_GA: 'en_MF',
    en_GH: 'en_MF',
    en_GM: 'en_MF',
    en_GN: 'en_MF',
    en_GQ: 'en_MF',
    en_GW: 'en_MF',
    en_KE: 'en_MF',
    en_KM: 'en_MF',
    en_LR: 'en_MF',
    en_LS: 'en_MF',
    en_LY: 'en_MF',
    en_MA: 'en_MF',
    en_MG: 'en_MF',
    en_ML: 'en_MF',
    en_MM: 'en_MF',
    en_MR: 'en_MF',
    en_MU: 'en_MF',
    en_MW: 'en_MF',
    en_NA: 'en_MF',
    en_NE: 'en_MF',
    en_NG: 'en_MF',
    en_RW: 'en_MF',
    en_SC: 'en_MF',
    en_SL: 'en_MF',
    en_SN: 'en_MF',
    en_SO: 'en_MF',
    en_ST: 'en_MF',
    en_SZ: 'en_MF',
    en_TD: 'en_MF',
    en_TG: 'en_MF',
    en_TN: 'en_MF',
    en_TZ: 'en_MF',
    en_UG: 'en_MF',
    en_ZA: 'en_MF',
    en_ZM: 'en_MF',
    en_ZW: 'en_MF',
    en_BH: 'en_MF',
    en_BD: 'en_AP',
    en_BN: 'en_AP',
    en_HK: 'en_AP',
    en_ID: 'en_AP',
    en_IL: 'en_AP',
    en_IN: 'en_IN',
    en_IQ: 'en_MF',
    en_JO: 'en_MF',
    en_KH: 'en_AP',
    en_KW: 'en_MF',
    en_LA: 'en_AP',
    en_LB: 'en_MF',
    en_LK: 'en_AP',
    en_MN: 'en_AP',
    en_MV: 'en_AP',
    en_MY: 'en_AP',
    en_NP: 'en_AP',
    en_OM: 'en_MF',
    en_PH: 'en_AP',
    en_PK: 'en_MF',
    en_PS: 'en_MF',
    en_QA: 'en_MF',
    en_SA: 'en_MF',
    en_SG: 'en_AP',
    en_TH: 'en_AP',
    en_TW: 'en_AP',
    en_VN: 'en_AP',
    en_YE: 'en_MF',
    ko_KR: 'ko_KR',
    ru_AM: 'ru_RU',
    ru_AZ: 'ru_RU',
    ru_GE: 'ru_RU',
    ru_KG: 'ru_RU',
    ru_KZ: 'ru_RU',
    ru_RU: 'ru_RU',
    ru_TJ: 'ru_RU',
    ru_TM: 'ru_RU',
    ru_UZ: 'ru_RU',
    tr_TR: 'tr_TR',
    zh_CN: 'zh_CN',
    en_CR: 'en_LA',
    en_DO: 'en_LA',
    en_GT: 'en_LA',
    en_HN: 'en_LA',
    en_JM: 'en_LA',
    en_NI: 'en_LA',
    en_PA: 'en_LA',
    en_PR: 'en_LA',
    en_SV: 'en_LA',
    ls_CR: 'ls_LA',
    ls_DO: 'ls_LA',
    ls_GT: 'ls_LA',
    ls_HN: 'ls_LA',
    ls_JM: 'ls_LA',
    ls_NI: 'ls_LA',
    ls_PA: 'ls_LA',
    ls_PR: 'ls_LA',
    ls_SV: 'ls_LA',
    de_AT: 'de_DE',
    de_BE: 'de_DE',
    de_CH: 'de_DE',
    de_DE: 'de_DE',
    de_LU: 'de_DE',
    de_ME: 'de_DE',
    en_AL: 'en_GB',
    en_BA: 'en_GB',
    en_BE: 'en_GB',
    en_BG: 'en_GB',
    en_CY: 'en_GB',
    en_CZ: 'en_GB',
    en_DK: 'en_GB',
    en_EE: 'en_GB',
    en_FI: 'en_GB',
    en_GB: 'en_GB',
    en_GL: 'en_GB',
    en_GR: 'en_GB',
    en_HR: 'en_GB',
    en_HU: 'en_GB',
    en_IE: 'en_GB',
    en_IS: 'en_GB',
    en_LT: 'en_GB',
    en_LU: 'en_GB',
    en_LV: 'en_GB',
    en_ME: 'en_GB',
    en_MK: 'en_GB',
    en_NL: 'en_GB',
    en_NO: 'en_GB',
    en_PT: 'en_GB',
    en_RO: 'en_GB',
    en_RS: 'en_GB',
    en_SE: 'en_GB',
    en_SI: 'en_GB',
    en_SK: 'en_GB',
    en_XK: 'en_GB',
    es_ES: 'es_ES',
    es_ME: 'es_ES',
    es_PT: 'es_ES',
    fr_BE: 'fr_FR',
    fr_CH: 'fr_FR',
    fr_FR: 'fr_FR',
    fr_LU: 'fr_FR',
    fr_ME: 'fr_FR',
    it_CH: 'it_IT',
    it_IT: 'it_IT',
    it_ME: 'it_IT',
    pl_PL: 'pl_PL',
    ru_BY: 'ru_RU',
    ru_MD: 'ru_RU',
    ru_UA: 'ru_RU',
    en_CA: 'en_CA',
    qf_CA: 'qf_CA',
    ue_US: 'ue_US',
    en_AU: 'en_AZ',
    en_NZ: 'en_AZ',
    en_PW: 'en_AZ',
    en_VU: 'en_AZ',
    bp_BR: 'bp_BR',
    en_AR: 'en_LA',
    en_BO: 'en_LA',
    en_CL: 'en_LA',
    en_CO: 'en_LA',
    en_EC: 'en_LA',
    en_MX: 'en_LA',
    en_PE: 'en_LA',
    en_PY: 'en_LA',
    en_UY: 'en_LA',
    en_VE: 'en_LA',
    ls_AR: 'ls_LA',
    ls_BO: 'ls_LA',
    ls_CL: 'ls_LA',
    ls_CO: 'ls_LA',
    ls_EC: 'ls_LA',
    ls_MX: 'ls_LA',
    ls_PE: 'ls_LA',
    ls_PY: 'ls_LA',
    ls_UY: 'ls_LA',
    ls_VE: 'ls_LA',
    xx_XX: 'en_GB'
};

const paymentCountries = {
    AF: 'Afghanistan',
    AL: 'Albania',
    DZ: 'Algeria',
    AS: 'American Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarctica',
    AG: 'Antigua and Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BS: 'Bahamas (the)',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgium',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia (Plurinational State of)',
    BQ: 'Bonaire, Sint Eustatius and Saba',
    BA: 'Bosnia and Herzegovina',
    BW: 'Botswana',
    BV: 'Bouvet Island',
    BR: 'Brazil',
    IO: 'British Indian Ocean Territory (the)',
    BN: 'Brunei Darussalam',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    CV: 'Cabo Verde',
    KH: 'Cambodia',
    CM: 'Cameroon',
    CA: 'Canada',
    KY: 'Cayman Islands (the)',
    CF: 'Central African Republic (the)',
    TD: 'Chad',
    CL: 'Chile',
    CN: 'China',
    CX: 'Christmas Island',
    CC: 'Cocos (Keeling) Islands (the)',
    CO: 'Colombia',
    KM: 'Comoros (the)',
    CD: 'Congo (the Democratic Republic of the)',
    CG: 'Congo (the)',
    CK: 'Cook Islands (the)',
    CR: 'Costa Rica',
    HR: 'Croatia',
    CU: 'Cuba',
    CW: 'Curaçao',
    CY: 'Cyprus',
    CZ: 'Czechia',
    DK: 'Denmark',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominican Republic (the)',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    EE: 'Estonia',
    SZ: 'Eswatini',
    ET: 'Ethiopia',
    FK: 'Falkland Islands (the) [Malvinas]',
    FO: 'Faroe Islands (the)',
    FJ: 'Fiji',
    FI: 'Finland',
    FR: 'France',
    GF: 'French Guiana',
    PF: 'French Polynesia',
    TF: 'French Southern Territories (the)',
    GA: 'Gabon',
    GM: 'Gambia (the)',
    GE: 'Georgia',
    DE: 'Germany',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Greece',
    GL: 'Greenland',
    GD: 'Grenada',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heard Island and McDonald Islands',
    VA: 'Holy See (the)',
    HN: 'Honduras',
    HK: 'Hong Kong',
    HU: 'Hungary',
    IS: 'Iceland',
    IN: 'India',
    ID: 'Indonesia',
    IR: 'Iran (Islamic Republic of)',
    IQ: 'Iraq',
    IE: 'Ireland',
    IM: 'Isle of Man',
    IL: 'Israel',
    IT: 'Italy',
    JM: 'Jamaica',
    JP: 'Japan',
    JE: 'Jersey',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    KR: 'Korea (the Republic of)',
    KW: 'Kuwait',
    KG: 'Kyrgyzstan',
    LV: 'Latvia',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libya',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macao',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Marshall Islands (the)',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexico',
    FM: 'Micronesia (Federated States of)',
    MD: 'Moldova (the Republic of)',
    MC: 'Monaco',
    MN: 'Mongolia',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MA: 'Morocco',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Netherlands (the)',
    NC: 'New Caledonia',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger (the)',
    NG: 'Nigeria',
    NU: 'Niue',
    NF: 'Norfolk Island',
    MP: 'Northern Mariana Islands (the)',
    NO: 'Norway',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestine, State of',
    PA: 'Panama',
    PG: 'Papua New Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines (the)',
    PN: 'Pitcairn',
    PL: 'Poland',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    MK: 'Republic of North Macedonia',
    RO: 'Romania',
    RU: 'Russian Federation (the)',
    RW: 'Rwanda',
    RE: 'Réunion',
    BL: 'Saint Barthélemy',
    SH: 'Saint Helena, Ascension and Tristan da Cunha',
    KN: 'Saint Kitts and Nevis',
    LC: 'Saint Lucia',
    MF: 'Saint Martin (French part)',
    PM: 'Saint Pierre and Miquelon',
    VC: 'Saint Vincent and the Grenadines',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'Sao Tome and Principe',
    SA: 'Saudi Arabia',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SX: 'Sint Maarten (Dutch part)',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SB: 'Solomon Islands',
    SO: 'Somalia',
    ZA: 'South Africa',
    GS: 'South Georgia and the South Sandwich Islands',
    SS: 'South Sudan',
    ES: 'Spain',
    LK: 'Sri Lanka',
    SD: 'Sudan (the)',
    SR: 'Suriname',
    SJ: 'Svalbard and Jan Mayen',
    SE: 'Sweden',
    CH: 'Switzerland',
    SY: 'Syrian Arab Republic',
    TW: 'Taiwan',
    TJ: 'Tajikistan',
    TZ: 'Tanzania, United Republic of',
    TH: 'Thailand',
    TL: 'Timor-Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad and Tobago',
    TN: 'Tunisia',
    TR: 'Turkey',
    TM: 'Turkmenistan',
    TC: 'Turks and Caicos Islands (the)',
    TV: 'Tuvalu',
    UG: 'Uganda',
    UA: 'Ukraine',
    AE: 'United Arab Emirates (the)',
    GB: 'United Kingdom of Great Britain and Northern Ireland (the)',
    UM: 'United States Minor Outlying Islands (the)',
    US: 'United States of America (the)',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VE: 'Venezuela (Bolivarian Republic of)',
    VN: 'Viet Nam',
    VG: 'Virgin Islands (British)',
    VI: 'Virgin Islands (U.S.)',
    WF: 'Wallis and Futuna',
    EH: 'Western Sahara',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
    AX: 'Åland Islands',
};

const local = process.env.NODE_ENV === 'production' ? false : true;

const allLabels = false;

const verboseModals = false;

const currency = {
    eur: '€',
    usd: '$',
    gbp: '£',
};

const recaptchaKey = '6LchX7YUAAAAALaFF4xAUqDfURboz-r6hmCoDHZc';

const proxy = 'http://proxy.eu.sony.com:10080';
const stripe = {
    apiKey: process.env.STRIPE_API_KEY ?? 'STRIPE_API_KEY',
    gradedStripeApiKey: process.env.STRIPE_OUTLET_API_KEY_PUBLIC ?? 'STRIPE_OUTLET_API_KEY_PUBLIC',
};

const postCodes = {
    gb: /^[A-Z]{1,2}[0-9][0-9A-Z]?\s?[0-9][A-Z]{2}$/,
    fr: /^\d{5}$/,
};

const typeAheadUri = 'api/typeahead/products';

const vatUri = 'api/validate/vat';

export {
    labelLanguages,
    supportedLocales,
    local,
    currency,
    allLabels,
    recaptchaKey,
    stripe,
    vatUri,
    postCodes,
    typeAheadUri,
    verboseModals,
    proxy,
    paymentCountries,
};
