/* global logger */

/**
 * @namespace helpers
 */

class parseUrl {
    /**
     * @description Class Helper class to parse NGP URL strings into a useable format for data layer and routing purposes
     *
     * @memberof helpers
     *
     *
     * @example
     * const urlParser = new parseUrl();
     */

    /**
     * Constructor class to initiate urlParser
     *
     * @example
     * const urlParser = new parseUrl();
     */
    constructor() {
        this.data = {};
        this.parameterLocationMap = ['topLevelCategory', 'category', 'subCategory'];

        this.parse = this.parse.bind(this);
        this.parseParams = this.parseParams.bind(this);
        this.getLocation = this.getLocation.bind(this);
        this.retrieve = this.getLocation.bind(this);
    }

    /**
     *
     * @param {string} url - `pathname` from ReactRouter (or manually constructed) to parse
     *
     * @example
     * const parsedLocation = urlParser.parse(pathname);
     * const { params = {}, search = '' } = parsedLocation;
     */

    parse(url) {
        /* parse language out or set to empty */

        const data = this.parseParams(url);

        this.data = {
            ...data
        };

        return this.data;
    }

    /**
     * Used internally by `parse` to create the parsed object
     *
     * @param {string} url - `pathname` from ReactRouter (or manually constructed) to parse
     */

    parseParams(url) {
        /* reverse the order and work backwards */
        url = url.length > 1 ? url.replace(/\/$/, '') : url;

        // logger.info(`src.helpers.parseUrl: Parsing URL: ${url}`);

        const split = url.replace('/pro/', '/').split('lang/');

        const position = split.length > 1 ? 1 : 0;

        let rawParams = split[position].split('/');

        if (rawParams[0] === '') {
            rawParams.shift();
        }

        let params = {};

        let localeTest = /^[a-zA-Z]{2}[-_][a-zA-Z]{2}$/.test(rawParams[0]);

        params.locale = url.includes('/lang/') || localeTest ? rawParams.shift() : 'xx_XX';

        const parsedPath = rawParams.join('/');

        params.logicalName = rawParams.pop();

        /* iterate the rest */

        this.parameterLocationMap.map((location, i) => {
            return (params[location] = rawParams[i] ? rawParams[i] : '');
        });

        return {
            params,
            raw: {
                path: url,
                parsed: `/${parsedPath}`,
                api: `/${params.locale}/api/dynamicmodules/${parsedPath}`
            }
        };
    }

    /**
     * Can be called directly by the user in order to create a compatible pathname string for parsing
     *
     * @param {string} href - fullly qualified or relative href value
     *
     * @example
     * const href = urlParser.getLocation('/pro/broadcast/camcorders');
     * const urlParser.parse(href);
     */

    getLocation(href) {
        let l = document.createElement('a');
        l.href = href;
        return l;
    }

    /**
     * Retrieve the stored data
     *
     * @example
     * const data = urlParser.retrieve();
     */

    retrieve() {
        return this.data;
    }
}

export default parseUrl;
