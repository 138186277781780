/* global logger */

/**
 * @namespace helpers
 */

import { replacePatternAttributes } from '../';

/**
 * @method miniGazelle
 * @description Translated key-value labelling methods
 *
 * @memberof helpers
 *
 *
 * @property {object} labels - Local caching of fetched labels specific to the current locale
 * @property {string|null} locale - Current locale
 * @property {method} setLabels - Updates the local label cache with fresh labels
 * @property {method} count - Returns the current number of labels in the cache by key
 * @property {method} unescapeQuotes - Unescapes quotes for the given string
 * @property {label} label - The primary method for miniGazelle. Returns the label string if present. Optionally can dynamically replace strings within the returned label.
 *
 * @example
 * return miniGazelle.label('labelKey', true); // Returns the label, true flag returns a 'not found' string if the key doesn't exist, otherwise the return value is null
 *
 */

let miniGazelle = {
    labels: {},
    locale: null,
    setLabels: function(labels) {
        if (labels) {
            this.labels = labels;
        } else {
            logger.warn('Something attempted to update the labels but passed me nothing!');
        }
    },
    count: function() {
        const labels = Object.keys(this.labels);
        const count = labels.length;

        return count;
    },
    unescapeQuotes: function(string) {
        return string.replace(/\\"/g, '"').replace(/\\"/g, '"');
    },
    label: function(label, showError, replacements = {}) {
        /* some labels that get passed by ProSony are hardcoded and need to have the dots "." removed */

        label = label ? label.replace(/\./g, '_').replace(/ /g, '') : null;

        showError = typeof showError === 'undefined' ? false : showError;
        let error = showError ? `Label : ${label}` : null;

        /* check if label should be blank */

        let returnLabel = this.labels[label] ? this.unescapeQuotes(this.labels[label]) : error;

        if (returnLabel === '{blank}') {
            returnLabel = null;
        }

        /**
         * Perform dynamic replacements
         */

        if (Object.entries(replacements).length > 0 && replacements.constructor === Object) {
            /**
             * replace pattern attributes
             */

            returnLabel = replacePatternAttributes({
                string: returnLabel,
                patternAttributes: replacements,
                noSpan: true
            });
        }

        return returnLabel;
    }
};

export default miniGazelle;
