import currentLocale from '../currentLocale/currentLocale';

/**
 * geoLocationApi
 * @returns string
 */
export default function geoLocationApi() {
    /* check the conf variable to see what URL we should use */
    let apiUrlBase;

    const locale = currentLocale.get();

    if (process.env.NODE_ENV === 'development') {
        apiUrlBase = 'http://localhost:8080/geo/popup';
        console.log('expect development:', process.env.NODE_ENV);
    }
    if (process.env.NODE_ENV === 'production') {
        apiUrlBase = `/${locale}/api/geo-location/popup`;
        console.log('expect production:', process.env.NODE_ENV);
    }

    return apiUrlBase;
}
