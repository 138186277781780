import loadable from '@loadable/component';

/**
 * @module components/index
 *
 * @description Exports all modules in a loadable manner for code splitting and smart bundling
 *
 *
 *
 */

const StandardText = loadable(() =>
    import(/* webpackChunkName: "components/StandardText" */ './modules/StandardText/StandardText')
);

const DealerLocator = loadable(() =>
    import(/* webpackChunkName: "components/DealerLocator" */ './modules/DealerLocator/DealerLocator')
);

const RichMedia = loadable(() =>
    import(/* webpackChunkName: "components/RichMedia" */ './modules/RichMedia/RichMedia')
);

const Navigation = loadable(() =>
    import(/* webpackChunkName: "components/Navigation" */ './modules/Navigation/Navigation')
);

const MediaTextColumns = loadable(() =>
    import(/* webpackChunkName: "components/MediaTextColumns" */ './modules/MediaTextColumns/MediaTextColumns')
);

const BulletPoints = loadable(() =>
    import(/* webpackChunkName: "components/BulletPoints" */ './modules/BulletPoints/BulletPoints')
);

const ContentCard = loadable(() =>
    import(/* webpackChunkName: "components/ContentCard" */ './modules/ContentCard/ContentCard')
);

const MultiPurposeBanner = loadable(() =>
    import(/* webpackChunkName: "components/MultiPurposeBanner" */ './modules/MultiPurposeBanner/MultiPurposeBanner')
);

const multiPurpose_banner_module = loadable(() =>
    import(
        /* webpackChunkName: "components/multiPurpose_banner_module" */ './modules/MultiPurposeBanner/MultiPurposeBanner'
    )
);

const BlockLinks = loadable(() =>
    import(/* webpackChunkName: "components/BlockLinks" */ './modules/BlockLinks/BlockLinks')
);

const AssetList = loadable(() =>
    import(/* webpackChunkName: "components/AssetList" */ './modules/AssetList/AssetList')
);

const AssetEvents = loadable(() =>
    import(/* webpackChunkName: "components/AssetEvents" */ './modules/AssetEvents/AssetEvents')
);

const AssetHighlights = loadable(() =>
    import(/* webpackChunkName: "components/AssetHighlights" */ './modules/AssetHighlights/AssetHighlights')
);

const AssetListTitleOnly = loadable(() =>
    import(/* webpackChunkName: "components/AssetListTitleOnly" */ './shared/AssetListTitleOnly/AssetListTitleOnly')
);

const AssetCarousel = loadable(() =>
    import(/* webpackChunkName: "components/AssetCarousel" */ './modules/AssetCarousel/AssetCarousel')
);

const Breadcrumb = loadable(() =>
    import(/* webpackChunkName: "components/Breadcrumb" */ './modules/Breadcrumb/Breadcrumb')
);

const HeroBanner = loadable(() =>
    import(/* webpackChunkName: "components/HeroBanner" */ './modules/HeroBanner/HeroBanner')
);

const Footer = loadable(() => import(/* webpackChunkName: "components/Footer" */ './modules/Footer/Footer'));

const ChangeCountry = loadable(() =>
    import(/* webpackChunkName: "components/ChangeCountry" */ './modules/ChangeCountry/ChangeCountry')
);

const ProductIntroBanner = loadable(() =>
    import(/* webpackChunkName: "components/ProductIntroBanner" */ './modules/ProductIntroBanner/ProductIntroBanner')
);

const ProductImageGallery = loadable(() =>
    import(/* webpackChunkName: "components/ProductImageGallery" */ './modules/ProductImageGallery/ProductImageGallery')
);

const ProductOverviewBlock = loadable(() =>
    import(
        /* webpackChunkName: "components/ProductOverviewBlock" */ './modules/ProductOverviewBlock/ProductOverviewBlock'
    )
);

const ProductFeaturesBlock = loadable(() =>
    import(
        /* webpackChunkName: "components/ProductFeaturesBlock" */ './modules/ProductFeaturesBlock/ProductFeaturesBlock'
    )
);

const ProductSpecificationsBlock = loadable(() =>
    import(
        /* webpackChunkName: "components/ProductSpecificationsBlock" */ './modules/ProductSpecificationsBlock/ProductSpecificationsBlock'
    )
);

const QuoteBlock = loadable(() =>
    import(/* webpackChunkName: "components/QuoteBlock" */ './modules/QuoteBlock/QuoteBlock')
);

const TextMediaVariable = loadable(() =>
    import(/* webpackChunkName: "components/TextMediaVariable" */ './modules/TextMediaVariable/TextMediaVariable')
);

const SiteMap = loadable(() => import(/* webpackChunkName: "components/SiteMap" */ './modules/SiteMap/SiteMap'));

const StickyNav = loadable(() =>
    import(/* webpackChunkName: "components/StickyNav" */ './modules/StickyNav/StickyNav')
);

const Glue = loadable(() => import(/* webpackChunkName: "components/Glue" */ './modules/Glue/Glue'));

const ProductCategory = loadable(() =>
    import(/* webpackChunkName: "components/ProductCategory" */ './modules/ProductCategory/ProductCategory')
);

const HomepageHero = loadable(() =>
    import(/* webpackChunkName: "components/HomepageHero" */ './modules/HomepageHero/HomepageHero')
);

const NewsLinkBlocks = loadable(() =>
    import(/* webpackChunkName: "components/NewsLinkBlocks" */ './modules/NewsLinkBlocks/NewsLinkBlocks')
);

const StandardTable = loadable(() =>
    import(/* webpackChunkName: "components/StandardTable" */ './modules/StandardTable/StandardTable')
);

const WhatsHot = loadable(() => import(/* webpackChunkName: "components/WhatsHot" */ './modules/WhatsHot/WhatsHot'));

const PSProductDetails = loadable(() =>
    import(/* webpackChunkName: "components/PSProductDetails" */ './modules/PSProductDetails/PSProductDetails')
);

/* RelatedAssets has aliases */

const RelatedAssets = loadable(() =>
    import(/* webpackChunkName: "components/RelatedAssets" */ './modules/RelatedAssets/RelatedAssets')
);

const RelatedAssetsDcq = loadable(() =>
    import(/* webpackChunkName: "components/RelatedAssetsDcq" */ './modules/RelatedAssets/RelatedAssets')
);

const RelatedProducts = loadable(() =>
    import(/* webpackChunkName: "components/RelatedProducts" */ './modules/RelatedAssets/RelatedAssets')
);

const RelatedProductsDcq = loadable(() =>
    import(/* webpackChunkName: "components/RelatedProductsDcq" */ './modules/RelatedAssets/RelatedAssets')
);

const RelatedPages = loadable(() =>
    import(/* webpackChunkName: "components/RelatedPages" */ './modules/RelatedAssets/RelatedAssets')
);

const RelatedPagesDcq = loadable(() =>
    import(/* webpackChunkName: "components/RelatedPagesDcq" */ './modules/RelatedAssets/RelatedAssets')
);

const RelatedDcq = loadable(() =>
    import(/* webpackChunkName: "components/RelatedDcq" */ './modules/RelatedAssets/RelatedAssets')
);

const TextMediaCarousel = loadable(() =>
    import(/* webpackChunkName: "components/TextMediaCarousel" */ './modules/TextMediaCarousel/TextMediaCarousel')
);

/**
 * SearchResults has aliases because it believe it is special
 */
const SearchResults = loadable(() =>
    import(/* webpackChunkName: "components/SearchResults" */ './modules/SearchResults/SearchResults')
);
const InsiteSearch = SearchResults;
const SupportSearch = SearchResults;
const PressReleaseSearch = SearchResults;

const CtaBlock = loadable(() => import(/* webpackChunkName: "components/CtaBlock" */ './modules/CtaBlock/CtaBlock'));

const AttachmentDownload = loadable(() =>
    import(/* webpackChunkName: "components/AttachmentDownload" */ './modules/AttachmentDownload/AttachmentDownload')
);

const RelatedProductAccessories = loadable(() =>
    import(
        /* webpackChunkName: "components/RelatedProductAccessories" */ './modules/RelatedProductAccessories/RelatedProductAccessories'
    )
);

const DynamicBanner = loadable(() =>
    import(/* webpackChunkName: "components/DynamicBanner" */ './modules/DynamicBanner/DynamicBanner')
);

const EnhancedRfi = loadable(() =>
    import(/* webpackChunkName: "components/EnhancedRfi" */ './modules/EnhancedRfi/EnhancedRfi')
);

const LargeFormatCarousel = loadable(() =>
    import(/* webpackChunkName: "components/LargeFormatCarousel" */ './modules/LargeFormatCarousel/LargeFormatCarousel')
);

const GenericPillar = loadable(() =>
    import(/* webpackChunkName: "components/GenericPillar" */ './modules/GenericPillar/GenericPillar')
);

const RFI = loadable(() => import(/* webpackChunkName: "components/RFI" */ './modules/RFI/RFI'));

const DynamicAsset = loadable(() =>
    import(/* webpackChunkName: "components/DynamicAsset" */ './modules/DynamicAsset/DynamicAsset')
);
const MarketoRFI = loadable(() =>
    import(/* webpackChunkName: "components/MarketoRFI" */ './modules/MarketoRFI/MarketoRFI')
);

const DynamicForm = loadable(() =>
    import(/* webpackChunkName: "components/DynamicForm" */ './modules/DynamicForm/DynamicForm')
);

const LinkableBannerBlock = loadable(() =>
    import(/* webpackChunkName: "components/LinkableBannerBlock" */ './modules/LinkableBannerBlock/LinkableBannerBlock')
);

const LoginRegistration = loadable(() =>
    import(/* webpackChunkName: "components/LoginRegistration" */ './modules/LoginRegistration/LoginRegistration')
);
const NavigationHub = loadable(() =>
    import(/* webpackChunkName: "components/NavigationHub" */ './modules/NavigationHub/NavigationHub')
);

const RegistrationVerification = loadable(() =>
    import(
        /* webpackChunkName: "components/RegistrationVerification" */ './modules/RegistrationVerification/RegistrationVerification'
    )
);

const ResetPasswordAction = loadable(() =>
    import(/* webpackChunkName: "components/ResetPasswordAction" */ './modules/ResetPasswordAction/ResetPasswordAction')
);

const ResetPasswordTrigger = loadable(() =>
    import(
        /* webpackChunkName: "components/ResetPasswordTrigger" */ './modules/ResetPasswordTrigger/ResetPasswordTrigger'
    )
);

const PrimeSupportProBlock = loadable(() =>
    import(
        /* webpackChunkName: "components/PrimeSupportProBlock" */ './modules/PrimeSupportProBlock/PrimeSupportProBlock'
    )
);

const PrimeSupport = loadable(() =>
    import(/* webpackChunkName: "components/PrimeSupport" */ './modules/PrimeSupport/PrimeSupport')
);

const ProductResourcesBlock = loadable(() =>
    import(
        /* webpackChunkName: "components/ProductResourcesBlock" */ './modules/ProductResourcesBlock/ProductResourcesBlock'
    )
);

const ProductSeries = loadable(() =>
    import(/* webpackChunkName: "components/ProductSeries" */ './modules/ProductSeries/ProductSeries')
);

const PartnerAssetView = loadable(() =>
    import(/* webpackChunkName: "components/PartnerAssetView" */ './modules/PartnerAssetView/PartnerAssetView')
);

const PromotionalBanner = loadable(() =>
    import(/* webpackChunkName: "components/PromotionalBanner" */ './modules/PromotionalBanner/PromotionalBanner')
);

const SwtContentList = loadable(() =>
    import(/* webpackChunkName: "components/SwtContentList" */ './modules/SwtContentList/SwtContentList')
);

const SwtContentSummaryBlock = loadable(() =>
    import(
        /* webpackChunkName: "components/SwtContentSummaryBlock" */ './modules/SwtContentSummaryBlock/SwtContentSummaryBlock'
    )
);

const SwtContentSummaryToC = loadable(() =>
    import(
        /* webpackChunkName: "components/SwtContentSummaryToC" */ './modules/SwtContentSummaryToC/SwtContentSummaryToC'
    )
);

const SwtContentView = loadable(() =>
    import(/* webpackChunkName: "components/SwtContentView" */ './modules/SwtContentView/SwtContentView')
);

const SwtImportantInformation = loadable(() =>
    import(
        /* webpackChunkName: "components/SwtImportantInformation" */ './modules/SwtImportantInformation/SwtImportantInformation'
    )
);

const SwtKeySupportArea = loadable(() =>
    import(/* webpackChunkName: "components/SwtKeySupportArea" */ './modules/SwtKeySupportArea/SwtKeySupportArea')
);

const SwtPopularTopics = loadable(() =>
    import(/* webpackChunkName: "components/SwtPopularTopics" */ './modules/SwtPopularTopics/SwtPopularTopics')
);

const FDLChangeCountry = loadable(() =>
    import(/* webpackChunkName: "components/FDLChangeCountry" */ './modules/FDLChangeCountry/FDLChangeCountry')
);

const UpdateDetails = loadable(() =>
    import(/* webpackChunkName: "components/UpdateDetails" */ './modules/UpdateDetails/UpdateDetails')
);

const Post = loadable(() => import(/* webpackChunkName: "components/Post" */ './modules/Post/Post'));

const PartnerAssetList = loadable(() =>
    import(/* webpackChunkName: "components/PartnerAssetList" */ './modules/PartnerAssetList/PartnerAssetList')
);

// shared

const ExpandableList = loadable(() =>
    import(/* webpackChunkName: "components/ExpandableList" */ './shared/ExpandableList/ExpandableList')
);

const Subscription = loadable(() =>
    import(/* webpackChunkName: "components/Subscription" */ './modules/Subscription/Subscription')
);
const Outlet = loadable(() =>
    import(/* webpackChunkName: "components/Outlet" */ './modules/Outlet/Outlet')
);
const outlet = loadable(() =>
    import(/* webpackChunkName: "components/Outlet" */ './modules/Outlet/Outlet')
);
const CopyrightBar = loadable(() =>
    import(/* webpackChunkName: "components/CopyrightBar" */ './modules/CopyrightBar/CopyrightBar')
);

export {
    Navigation,
    Footer,
    RichMedia,
    StandardText,
    BulletPoints,
    MultiPurposeBanner,
    multiPurpose_banner_module,
    ContentCard,
    BlockLinks,
    MediaTextColumns,
    AssetList,
    AssetListTitleOnly,
    AssetEvents,
    AssetCarousel,
    AssetHighlights,
    Breadcrumb,
    HeroBanner,
    ChangeCountry,
    ProductOverviewBlock,
    ProductImageGallery,
    ProductFeaturesBlock,
    ProductIntroBanner,
    ProductSpecificationsBlock,
    QuoteBlock,
    TextMediaVariable,
    SiteMap,
    StickyNav,
    Glue,
    ProductCategory,
    HomepageHero,
    NewsLinkBlocks,
    StandardTable,
    WhatsHot,
    PSProductDetails,
    RelatedAssets,
    RelatedAssetsDcq,
    RelatedDcq,
    RelatedPages,
    RelatedPagesDcq,
    RelatedProducts,
    RelatedProductsDcq,
    TextMediaCarousel,
    SearchResults,
    InsiteSearch,
    SupportSearch,
    PressReleaseSearch,
    CtaBlock,
    AttachmentDownload,
    RelatedProductAccessories,
    DynamicBanner,
    EnhancedRfi,
    LargeFormatCarousel,
    GenericPillar,
    RFI,
    DynamicAsset,
    DynamicForm,
    LinkableBannerBlock,
    LoginRegistration,
    RegistrationVerification,
    ResetPasswordAction,
    ResetPasswordTrigger,
    PrimeSupportProBlock,
    PrimeSupport,
    ProductResourcesBlock,
    ProductSeries,
    PartnerAssetView,
    PromotionalBanner,
    SwtContentList,
    SwtContentSummaryBlock,
    SwtContentSummaryToC,
    SwtContentView,
    SwtImportantInformation,
    SwtKeySupportArea,
    SwtPopularTopics,
    FDLChangeCountry,
    DealerLocator,
    ExpandableList,
    UpdateDetails,
    Post,
    Subscription,
    PartnerAssetList,
    NavigationHub,
    MarketoRFI,
    Outlet,
    outlet,
    CopyrightBar,
};
