/* global logger */

/**
 * @namepsace helpers
 */

import { currency } from '../../config/client';

export default ({ patternAttributes = {}, string = '', noSpan = false }) => {
    /**
     * @method replacePatternAttributes
     * @description E-commerce helper to take any content string and global search for patterns to dynamically replace, e.g. pricing, contract length, currency
     *
     * @memberof helpers
     *
     *
     * @param {object} patternAttributes - List of patternAttributes to replace in key: value format. Supported currencies are automatically replaced
     * @param {string} string - The string to hunt for replacements in
     * @param {boolean=} noSpan - A flag to disable wrapping <span> being inserted
     *
     * @example
     * const patternAttributes = {
     *  '${Octopus.BroadcastingHours}$': 24,
     *  '${Stripe.Currency}$': 'gbp',
     * };
     * const string = '${Octopus.BroadcastingHours}$ hours for ${Stripe.Currency}$1000';
     *
     * return replacePatternAttributes({ patternAttributes, string }); // => <span className="replaced">24</span> hours for <span className="replaced">£</span>1000
     */

    /**
     * Yo dawg, I heard you like string replacement,
     * so I put a currency string replacement in your
     * string replacement so you can string replace
     * while you string replace
     */

    /**
     * As a bonus, I put a regex in your regex so you
     * can global search while you global search
     */

    /* walk patternAttributes object and replace within string */

    string = String(string);

    try {
        if (patternAttributes && patternAttributes !== null && typeof patternAttributes === 'object') {
            Object.keys(patternAttributes).forEach(key => {
                const replacement = currency[String(patternAttributes[key]).toLowerCase()]
                    ? String(currency[String(patternAttributes[key]).toLowerCase()])
                    : String(patternAttributes[key]);

                /**
                 * Perform a global search and replace all instances of the string
                 */

                const regex = new RegExp(`${String(key)}`.replace(/\$/g, '\\$'), 'g');

                string = string.replace(
                    regex,
                    noSpan ? String(replacement) : `<span class="replaced">${String(replacement)}</span>`
                );
            });
        } else {
            logger.info(
                'replacePatternAttributes.js - Skipping replacement as a non-object has been provided instead of attributes'
            );
        }
    } catch (error) {
        logger.error('replacePatternAttributes.js - Failed to replace patternAttributes');
        logger.error(error);
    }

    return string;
};
