import { useState, useEffect } from 'react';

export default function useScreenSize() {
    // Initialize state with undefined width/height so server and client renders match
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    const [isMobileScreen, setIsMobileScreen] = useState(false);
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            if (document.body.clientWidth < 769) {
                setIsMobileScreen(true);
            } else {
                setIsMobileScreen(false);
            }
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener('resize', handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return {
        windowSize,
        isMobileScreen,
    };
}
